   
@media (max-width: 1180px)and(orientation: landscape) {
    @keyframes MenuContener {
        0% {
            min-height:  0vh;
            max-height:  0vh;
        }
        
        100% {
            min-height:  93vh;
            max-height:  93vh;
        }
    }
    
    .HeaderNavContainer, .HeaderNavContainerWhite, .HeaderNavContainerBlack{
        z-index: 150;
        min-width: 100vw;
        max-width: 100vw;
        min-height: 8vh;
        display: flex;
        flex-direction: column;
        background-color: none;
        border: none;
        .navigationMenu{
            min-width: 100vw;
            max-width: 100vw;
            background-color: none;
            border: none;
            .MenuBottoms{
                display: flex;
                flex-direction: row;
                min-height: 8vh;     

                .containerTBE{
                    background-color: transparent;
                    cursor: pointer;
                    display: flex;
                    margin: 0;
                    align-items: center;
                    justify-content: center;
                    min-height: 8vh;
                    min-width: 30vw;
                    width: 10vw;
                    border: none;
                    .LinkLogo{
                        display: flex;
                        justify-content: center;
                        max-width: 8vw;     
                    }
                    
                    .LinkLogo >  svg{
                        width: 15vw;
                    }
                }
                .containerLinks{
                    border: none;
                    background-color: #FF4700;
                    display: flex;
                    justify-content: flex-end;                
                    align-items: center;
                    padding-right: 5vw;
                    background-color: none;
                    .imagen{
                        display: none;
                        margin-right: 10vw;
                    }
                    .imagenMovil{
                        display: flex;
                        margin-right: 10vw;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0;
                        img{
                            width: 150%;
                        }
                    }
                }
                .containerBotton{
            background-color: #FF4700;
            border: none;
                    display: flex;                    
                    justify-content: flex-start;
                    align-items: center;
                    min-width: 15vw;
                    
                    color: #FFF;
                }
            }
        }
        .MenuTitleHeaders{
            min-height: 0vh;
            display: flex;
            flex-direction: row;
            div{
                width: 30vw;
            }
            .elementThree {
               
                min-width: 72vw;
                flex-grow: 2;
                display: flex;
                flex-direction: column;
                background-color: #FF4700;
                border: none;
                ul{
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    width: 100%;
                    min-height: 40%;
                    animation: textodesaparece 1s;
                    li{
                        
                        cursor: pointer;
                        width: 80%;
                        margin-left: 10%;                        
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        border-bottom: 1px solid #FFF;
                        list-style: none;
                        color: #FFF;
                        font-size: 1rem;
                        font-family: Montserrat;
                        min-height: 20%;
                        font-weight: 600;
                        display: none;
                        .Links{
                            font-size: 2rem;                           
                        }
                        
                    }
                    li:hover {
                        .Links{
                            color:#FFF;
                        }
                    }

                    margin: 0;
                    margin-top: 20%;
                }            
                @keyframes textodesaparece {
                    0%{
                        display: flex;
                        font-size: 1rem;
                    }
                    100%{
                        display: none;
                        font-size: 0rem;
                    }
                }
                div{
                    width: 90%;
                    
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-direction:row;
                    justify-content: flex-start;
                    margin-left: 10%;

                    .imagen{
                        margin: 0px;
                        margin-right: 5vw;
                        min-width: 10vw;
                        max-width: 10vw;                       
                        padding: 0;
                    }
                    
                }
            }
        }
    }
    .HeaderNavContainer{      
        .navigationMenu{     
            .MenuBottoms{             
                .containerTBE{                  
                    background-color: #FF4700;
                    min-width: 15vw;         
                    border: none;
                }
                .containerLinks{
                    background-color: #FF4700;       
                    min-width: 70vw;                 
                    border: none;
                }
                .containerBotton{
                    background-color: #FF4700;                   
                    min-width: 15vw;
                    border: none;
                }
            }
        }

    }
    .HeaderNavContainerWhite{      
        .navigationMenu{     
            .MenuBottoms{            
                .containerTBE{                  
                    background-color: #FFF;
                    min-width: 15vw;  
                    border: none;
                }
                .containerLinks{
                    background-color: #FFF;                 
                }
                .containerBotton{
                    background-color: #FFF;  
                    min-width: 15vw;                 
                }
            }
        }

    }
    .HeaderNavContainerBlack{      
        .navigationMenu{     
            .MenuBottoms{            
                .containerTBE{                  
              //      background-color: #000;  
                    min-width: 15vw;               
                }
                .containerLinks{
                //    background-color: #000;                 
                }
                .containerBotton{
                 //   background-color: #000; 
                    min-width: 15vw;                  
                }
            } 
        }

    }


    .MenuTitleHeaders{
        min-height: 92vh;
        display: flex;
        flex-direction: row;
        div{
            width: 30vw;
            background-color: #FF4700;
        }
//background-color: rgba( 0,0,0,  0.4);
        .elementThree {
            min-width: 70vw;
            flex-grow: 2;
            display: flex;
            flex-direction: column;
            ul{
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                width: 100%;
                min-height: 95%;
                li{
                    cursor: pointer;
                    width: 80%;
                    margin-left: 10%;                        
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    border-bottom: 1px solid #FFF;
                    list-style: none;
                    color: #FFF;
                    font-size: 1rem;
                    font-family: Montserrat;
                    min-height: 20%;
                    font-weight: 600;
                    .Links{
                        font-size: 0.8rem;
                    }
                }
                li:hover{
                    color: #FFF;
                }
                margin: 0;
                margin-top: 20%;
            }            
            div{
                width: 90%;
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction:row;
                justify-content: flex-start;
                margin-left: 10%;
                .imagen{
                    margin: 0px;
                    margin-right: 5vw;
                    min-width: 10vw;
                    max-width: 10vw;                       
                    padding: 0;
                    
                }
             
            }
        }
    }


    /*      HEADER CLOSED       */
    
    .HeaderNavContainerOpen,  .HeaderNavContainerOpenWhite,  .HeaderNavContainerOpenBlack{
        z-index: 150;
        min-width: 100vw;
        max-width: 100vw;
        min-height: 8vh;
        display: flex;
        flex-direction: column;
        background-color: #FF4700;
      //  background-color: rgba( 0,0,0,  0.4);
        .navigationMenu{
            min-width: 100vw;
            max-width: 100vw;
            background-color: #FF4700;
            .MenuBottoms{
                display: flex;
                flex-direction: row;
                min-height: 8vh;             
                .containerTBE{
                    background-color: transparent;
                    cursor: pointer;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    min-height: 8vh;
                    min-width: 25vw;
                    .LinkLogo{
                        max-width: 9vw;     
                        display: flex;
                        justify-content: flex-start;                                      
                        margin-right: 13vw;
                    }
                    .LinkLogo >  svg{
                        width: 15vw;
                    }
                }
                .containerLinks{
                    background-color: #FF4700;
                    display: flex;
                    justify-content: flex-end;                
                    align-items: center;
                    padding-right: 5vw;
                    background-color: none;
                    img{
                        margin-left: 2vw;
                        min-width: 2vw;
                    }
                }
                .containerBotton{
            background-color: #FF4700;

                    display: flex;                    
                    justify-content: flex-start;
                    align-items: center;
                    min-width: 15vw;
                    
                    color: #FFF;
                }
            }
        }
        .MenuTitleHeaders{
            min-height: 92vh;
            display: flex;
            flex-direction: row;
            div{
                width: 30vw;
            }
            .elementThree {
                min-width: 72vw;
                flex-grow: 2;
                display: flex;
                flex-direction: column;
                background-color: #FF4700;
                ul{
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    width: 100%;
                    min-height: 40%;
                    li{
                        cursor: pointer;
                        width: 80%;
                        margin-left: 10%;                        
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        border-bottom: 1px solid #FFF;
                        list-style: none;
                        color: #FFF;
                        font-size: 1rem;
                        font-family: Montserrat;
                        min-height: 20%;
                        font-weight: 600;
                        .Links{
                            font-size: 2rem;
                        }
                        .Links:hover{
                            font-size: 2rem;
                            color: #FFF;
                        }
                    }
                    li:hover{
                        color: #FFF;
                        animation: none;
                    }
                    margin: 0;
                    margin-top: 20%;
                }            
                div{
                    width: 90%;
                    
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-direction:row;
                    justify-content: flex-start;
                    margin-left: 10%;
                    .imagen{
                        display: none;
                    }
                    .imagenMovil{
                        display: flex;
                        margin: 0px;
                        margin-right: 5vw;
                        min-width: 10vw;
                        max-width: 10vw;                       
                        padding: 0;
                        img{
                            width: 150%;
                        }
                    }
                    
                }
            }
        }
    }
}
















   
@media (max-width: 1180px)and(orientation: portrait) {
    @keyframes MenuContener {
        0% {
            min-height:  0vh;
            max-height:  0vh;
        }
        
        100% {
            min-height:  93vh;
            max-height:  93vh;
        }
    }
    
    .HeaderNavContainer, .HeaderNavContainerWhite, .HeaderNavContainerBlack{
        z-index: 150;
        min-width: 100vw;
        max-width: 100vw;
        min-height: 8vh;
        display: flex;
        flex-direction: column;
        background-color: none;
        .navigationMenu{
            min-width: 100vw;
            max-width: 100vw;
            background-color: none;
            background-color: rex;

            .MenuBottoms{
                display: flex;
                flex-direction: row;
                min-height: 8vh;         
                .containerTBE{
                    background-color: transparent;
                    cursor: pointer;
                    display: flex;
                    margin: 0;
                    align-items: center;
                    justify-content: center;
                    min-height: 8vh;
                    min-width: 30vw;
                    width: 10vw;
                    .LinkLogo{
                        display: flex;
                        justify-content: center;
                        max-width: 8vw;     
                    }
                    .LinkLogo >  svg{
                        width: 15vw;
                    }
                }
                .containerLinks{
                    background-color: #FF4700;
                    display: flex;
                    justify-content: flex-end;                
                    align-items: center;
                    padding-right: 5vw;
                    background-color: none;
                    width: 80vw;
                    .imagen{
                        display: none;
                        margin-right: 10vw;
                    }
                    .imagenMovil{
                        display: flex;
                        margin-right: 10vw;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0;
                        img{
                            width: 150%;
                        }
                    }
                }
                .containerBotton{
                    background-color: #FF4700;
                    display: flex;                    
                    justify-content: flex-start;
                    align-items: center;
                    min-width: 15vw;
                    color: #FFF;
                }
            }
        }
        .MenuTitleHeaders{
            min-height: 0vh;
            display: flex;
            flex-direction: row;
            div{
                width: 30vw;
            }
            .elementThree {
               
                min-width: 72vw;
                flex-grow: 2;
                display: flex;
                flex-direction: column;
                background-color: #FF4700;
                ul{
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    width: 100%;
                    min-height: 40%;
                    animation: textodesaparece 1s;
                    li{
                        
                        cursor: pointer;
                        width: 80%;
                        margin-left: 10%;                        
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        border-bottom: 1px solid #FFF;
                        list-style: none;
                        color: #FFF;
                        font-size: 1rem;
                        font-family: Montserrat;
                        min-height: 20%;
                        font-weight: 600;
                        display: none;
                        .Links{
                            font-size: 2rem;                           
                        }
                        
                    }
                    li:hover {
                        .Links{
                            color:#FFF;
                        }
                    }

                    margin: 0;
                    margin-top: 20%;
                }            
                @keyframes textodesaparece {
                    0%{
                        display: flex;
                        font-size: 1rem;
                    }
                    100%{
                        display: none;
                        font-size: 0rem;
                    }
                }
                div{
                    width: 90%;
                    
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-direction:row;
                    justify-content: flex-start;
                    margin-left: 10%;

                    .imagen{
                        margin: 0px;
                        margin-right: 5vw;
                        min-width: 10vw;
                        max-width: 10vw;                       
                        padding: 0;
                    }
                    
                }
            }
        }
    }
    .HeaderNavContainer{      
        .navigationMenu{     
            .MenuBottoms{            
                .containerTBE{                  
                    background-color: #FF4700;
                    min-width: 15vw;         
                }
                .containerLinks{
                    background-color: #FF4700;                 
                }
                .containerBotton{
                    background-color: #FF4700;                   
                    min-width: 15vw;
                }
            }
        }

    }
    .HeaderNavContainerWhite{      
        .navigationMenu{     
            .MenuBottoms{            
                .containerTBE{                  
                    background-color: #FFF;
                    min-width: 15vw;  
                }
                .containerLinks{
                    background-color: #FFF;                 
                }
                .containerBotton{
                    background-color: #FFF;  
                    min-width: 15vw;                 
                }
            }
        }

    }
    .HeaderNavContainerBlack{      
        .navigationMenu{     
            .MenuBottoms{            
                .containerTBE{                  
              //      background-color: #000;  
                    min-width: 15vw;               
                }
                .containerLinks{
                //    background-color: #000;                 
                }
                .containerBotton{
                 //   background-color: #000; 
                    min-width: 15vw;                  
                }
            } 
        }

    }


    .MenuTitleHeaders{
        min-height: 92vh;
        display: flex;
        flex-direction: row;
        div{
            width: 30vw;
            background-color: #FF4700;
        }
        .elementThree {
            min-width: 70vw;
            flex-grow: 2;
            display: flex;
            flex-direction: column;
            ul{
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                width: 100%;
                min-height: 95%;
                li{
                    cursor: pointer;
                    width: 80%;
                    margin-left: 10%;                        
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    border-bottom: 1px solid #FFF;
                    list-style: none;
                    color: #FFF;
                    font-size: 1rem;
                    font-family: Montserrat;
                    min-height: 20%;
                    font-weight: 600;
                    border: none;
                    .Links{
                        font-size: 0.8rem;
                    }
                }
                li:hover{
                    color: #FFF;
                }
                margin: 0;
                margin-top: 20%;
            }            
            div{
                width: 90%;
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction:row;
                justify-content: flex-start;
                margin-left: 10%;
                .imagen{
                    margin: 0px;
                    margin-right: 5vw;
                    min-width: 10vw;
                    max-width: 10vw;                       
                    padding: 0;
                    
                }
             
            }
        }
    }


    /*      HEADER CLOSED       */
    
    .HeaderNavContainerOpen,  .HeaderNavContainerOpenWhite,  .HeaderNavContainerOpenBlack{
        z-index: 150;
        min-width: 100vw;
        max-width: 100vw;
        min-height: 8vh;
        display: flex;
        flex-direction: column;
        background-color: #FF4700;
        border: none;
        .navigationMenu{
            min-width: 100vw;
            max-width: 100vw;
            background-color: #FF4700;
            border: none;
            .MenuBottoms{
                display: flex;
                flex-direction: row;
                min-height: 8vh;             
                .containerTBE{
                    background-color: transparent;
                    cursor: pointer;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    min-height: 8vh;
                    min-width: 25vw;
                    .LinkLogo{
                        max-width: 9vw;     
                        display: flex;
                        justify-content: flex-start;                                      
                        margin-right: 13vw;
                    }
                    .LinkLogo >  svg{
                        width: 15vw;
                    }
                }
                .containerLinks{
                    background-color: #FF4700;
                    display: flex;
                    justify-content: flex-end;                
                    align-items: center;
                    padding-right: 5vw;
                    background-color: none;
                    border: none;
                    img{
                        margin-left: 2vw;
                        min-width: 2vw;
                    }
                }
                .containerBotton{
                    background-color: #FF4700;
                    display: flex;                    
                    justify-content: flex-start;
                    align-items: center;
                    min-width: 15vw;                    
                    color: #FFF;
                    border: none;
                }
            }
        }
        .MenuTitleHeaders{
            min-height: 92vh;
            display: flex;
            flex-direction: row;
            div{
                width: 30vw;
            }
            .elementThree {
                min-width: 72vw;
                flex-grow: 2;
                display: flex;
                flex-direction: column;
                background-color: #FF4700;
                border: none;
                ul{
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    width: 100%;
                    min-height: 40%;
                    li{
                        cursor: pointer;
                        width: 80%;
                        margin-left: 10%;                        
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        border-bottom: 1px solid #FFF;
                        list-style: none;
                        color: #FFF;
                        font-size: 1rem;
                        font-family: Montserrat;
                        min-height: 20%;
                        font-weight: 600;
                        .Links{
                            font-size: 2rem;
                        }
                        .Links:hover{
                            font-size: 2rem;
                            color: #FFF;
                        }
                    }
                    li:hover{
                        color: #FFF;
                        animation: none;
                    }
                    margin: 0;
                    margin-top: 20%;
                }            
                div{
                    width: 90%;
                    
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-direction:row;
                    justify-content: flex-start;
                    margin-left: 10%;
                    .imagen{
                        display: none;
                    }
                    .imagenMovil{
                        display: flex;
                        margin: 0px;
                        margin-right: 5vw;
                        min-width: 10vw;
                        max-width: 10vw;                       
                        padding: 0;
                        img{
                            width: 150%;
                        }
                    }
                    
                }
            }
        }
    }
}